<template>
  <div>
    <b-overlay
      :show="show"
      variant="transparent"
      opacity="0.99"
      blur="5px"
      rounded="sm"
    >
      <template #overlay>
        <div class="text-center">
          <b-icon-controller
            font-scale="3"
            animation="cylon"
          />
          <p id="cancel-label">
            กรุณารอสักครู่...
          </p>
        </div>
      </template>
      <div class="container bg-first">
        <b-row>
          <b-col md="12">
            <article class="mt-2 top-search lottery-search pb-2 pt-2">
              <div class="text-center">
                <h1><i class="fa-solid fa-magnifying-glass" /> ค้นหาสลากฯ</h1>
              </div>
              <div class="container-sm">
                <h5>งวดวันที่ {{ round.roundth }}</h5>
                <div class="d-flex justify-content-center">
                  <otp-input
                    v-if="renderComponent"
                    v-model="input_numlot"
                    :digits="6"
                  >
                    <template #errorMessage>
                      There is an error
                    </template>
                  </otp-input>

                </div>

                <b-row class="mt-3">
                  <b-col cols="4">
                    <b-button
                      block
                      size="lg"
                      variant="outline-primary"
                      @click="cleartext()"
                    >
                      เคลียร์
                    </b-button>
                  </b-col>
                  <b-col cols="8">
                    <b-button
                      block
                      size="lg"
                      class="btn-search"
                      @click="Search()"
                    >
                      ค้นหา
                    </b-button>
                  </b-col>
                </b-row>

              </div>
            </article>
          </b-col>
        </b-row>

        <!-- <b-card class="mt-4">
        <div class="d-flex align-items-center justify-content-center">
          <span>เลือกชุดหวย :</span>
          <b-button class="ml-1 btn-clear">
            1 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            2 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            3 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            4 ใบ
          </b-button>
          <b-button class="ml-1 btn-clear">
            5 ใบ
          </b-button>
        </div>
      </b-card> -->

        <div>
          <div class="mt-2">
            <h3 class="text-white text-center">
              ผลการค้นหา
            </h3>
          </div>

          <b-row>
            <b-col
              v-for="item in lotteryset"
              :key="item._id"
              md="6"
              lg="4"
              cols="12"
              class="mt-2"
            >
              <div class="lotto-items">
                <div class="card7">
                  <div class="chip">
                    <span class="bankk">{{ item.lottonumber }}</span>
                  </div>
                  <div class="number">
                    {{ item.roundth }}
                  </div>
                  <div class="name">
                    <div class="price-lotto">
                      <h1> {{ item.price }} บาท</h1>
                    </div>
                  </div>
                  <div class="from">
                    <span class="txt-a">งวดที่</span> 1
                  </div>
                  <div class="to">
                    <span class="txt-a">ชุดที่</span> {{ item.series_no }}
                  </div>
                </div>
                <div class="mt-1">
                  <b-button
                    block
                    size="lg"
                    variant="primary"
                    class="btn-add"
                    @click="addcard(item)"
                  >
                    <i class="fas fa-shopping-basket" /> เพิ่มลงตะกร้า
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
        </div>
        <div class="mt-1">
          <b-pagination
            v-model="currentPage"
            class="mt-1"
            :per-page="perPage"
            hide-goto-end-buttons
            :total-rows="totalRows"
            first-number
            last-number
            align="center"
            @input="Search()"
          />

          <br><br>
        </div>
      </div>
    </b-overlay>
  </div>
</template>

<script>
import {
  BButton,
  BCol,
  BRow,
  BPagination,
  BOverlay,
  BIconController,
} from 'bootstrap-vue'
import OtpInput from '../components/OtpInput.vue'

export default {
  components: {
    BButton,
    BCol,
    BRow,
    OtpInput,
    BPagination,
    BOverlay,
    BIconController,
  },
  data() {
    return {
      renderComponent: true,
      input_numlot: '',
      slide: 0,
      sliding: null,
      lotteryset: [],
      round: null,
      totalRows: 1,
      currentPage: 1,
      perPage: 15,
      show: false,
      UserData: JSON.parse(localStorage.getItem('userData')),
    }
  },
  mounted() {
    this.getBalance()
    localStorage.removeItem('otp')
    localStorage.removeItem('type')
    this.getround()
    if (this.UserData) {
      this.getLotteryByagent()
    } else {
      this.getLottery()
    }
    this.lastlogin()
    this.loginstampAtdays()
  },
  methods: {
    lastlogin() {
      this.$http
        .get('https://api.sabaideelotto.com/api/users/lastlogin')
        // eslint-disable-next-line no-unused-vars
        .then(response => {

        })
        .catch(error => console.log(error))
    },
    loginstampAtdays() {
      this.$http
        .get('https://api.sabaideelotto.com/api/users/loginstampAtdays')
        // eslint-disable-next-line no-unused-vars
        .then(response => {

        })
        .catch(error => console.log(error))
    },
    getBalance() {
      this.$http
        .get('https://api.sabaideelotto.com/api/zean/balance/getbalanceuser')
        .then(ress => {
          // console.log(ress.data)
          // this.balance = ress.data.balance
          localStorage.setItem('balance', JSON.stringify(ress.data.balance))
        })
    },
    Search() {
      if (this.UserData) {
        this.getLotteryByagent()
      } else {
        this.getLottery()
      }
    },
    getround() {
      this.$http
        .get('https://api.sabaideelotto.com/api/lottery/getrounddetail')
        .then(ress => {
          // console.log(ress.data)
          this.round = ress.data
        })
    },
    detail(item) {
      this.$router.push({ name: 'lottery-set-agent', params: { id: item } })
    },
    getLotteryByagent() {
      this.show = true
      const Obj = {
        page: this.currentPage,
        lottotype: JSON.parse(localStorage.getItem('type')),
        lottonumber: JSON.parse(localStorage.getItem('otp')),
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/home/getlottoByagent', Obj)
        .then(response => {
          this.lotteryset = response.data.lottery
          this.totalRows = response.data.total
          this.show = false
          // localStorage.removeItem('otp')
          // localStorage.removeItem('type')
          // console.log(this.lotteryset)
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    getLottery() {
      this.show = true
      const Obj = {
        page: this.currentPage,
        lottotype: JSON.parse(localStorage.getItem('type')),
        lottonumber: JSON.parse(localStorage.getItem('otp')),
      }
      this.$http
        .post('https://api.sabaideelotto.com/api/lottery/home/getlotto', Obj)
        .then(response => {
          this.lotteryset = response.data.lottery
          this.totalRows = response.data.total
          this.show = false
          // localStorage.removeItem('otp')
          // localStorage.removeItem('type')
          // console.log(this.lotteryset)
        })
        .catch(error => {
          this.show = false
          console.log(error)
        })
    },
    cleartext() {
      this.input_numlot = ''
      localStorage.removeItem('otp')
      localStorage.removeItem('type')
      // OtpInput Component
      this.renderComponent = false
      this.$nextTick(() => {
        // Adding the component back in
        this.renderComponent = true
      })
      this.getLottery()
    },
    addcard(lotto) {
      // console.log(localStorage.getItem('userData'))
      if (localStorage.getItem('userData')) {
        const Obj = {
          // eslint-disable-next-line no-underscore-dangle
          lottonumber: lotto.lottonumber,
        }
        this.$http
          .post('https://api.sabaideelotto.com/api/lottery/addcart', Obj)
          .then(() => {
            this.getLotteryByagent()
            this.Success('ล็อตเตอรรี่เพิ่มลงตะกร้าเรียบร้อย')
          })
          .catch(error => console.log(error))
      } else {
        this.SwalError('กรุณา เข้าสู่ระบบก่อนทำการซื้อ')
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        title: '<h3 style="color: #141414">ลงตะกร้าสำเร็จ</h3>',
        text: mes,
        showConfirmButton: false,
        customClass: {
          confirmButton: 'btn btn-success',
        },
        timer: 2000,
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: '<h3 style="color: #141414">ขออภัยค่ะ !!</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
  },
}
</script>

<style scoped>

</style>
